/// <reference types="@types/segment-analytics" />
import { kebabCase, lowerCase } from 'lodash';
import { useCallback } from 'react';

import { APP_VERSION } from 'helpers/constants';
import { isElemenoUser, userHasManagerPrivileges } from 'helpers/isUser';
import { useGroupsPublicQuery } from 'gql/queries/useGroupsPublicQuery';
import { SessionUserFragment } from 'gql/mutations/useStartSessionMutation';
import { useSiteContext } from 'contexts/SiteContext';
import { UserFragment } from 'models/user';
import { getPrimaryTeamId, UserIdentityFragment } from 'models/userInfo';

import { AnalyticsUserTraits, getAnalyticsService, enableEmbargo } from './AnalyticsService';
import { AnalyticsEvent } from './AnalyticsEvents';

export type AnalyticsEventOrEvents = AnalyticsEvent | AnalyticsEvent[];

/**
 * Get role of user from user object.
 * @param user
 * @returns string
 */
function getRole(user: UserFragment): string {
	if (!user) {
		return 'no_user';
	}
	if (isElemenoUser(user)) {
		return 'elemeno';
	}
	return userHasManagerPrivileges(user) ? 'manager' : 'user';
}

/**
 * Get type of site (typically "PREMIUM") from site configuration.
 * @param siteConfig
 * @returns string
 */
function getSiteType(): string {
	const type = window.SITE_TYPE;
	if (type === 'PREMIUM') {
		return 'enterprise';
	} else {
		return type ? type.toLowerCase() : 'not_specified';
	}
}

let _userType; // set on identify
let _primaryTeam;

/**
 * Add the site type directly to the event to make filtering for customer
 * events much easier.
 * @param props Event props
 * @returns modified event props
 */
export const wrapProps = (props): Record<string, any> => ({
	siteType: getSiteType(),
	userType: _userType,
	eventTeam: _primaryTeam, // if set
	gitHash: APP_VERSION.release,
	clientVersion: APP_VERSION.release,
	envSite: window.SITE_ID,
	...props,
});

function getCompany(user: UserFragment): { id: string; name: string } {
	if (!user) {
		return { id: 'no_user', name: 'No Company' };
	}
	if (isElemenoUser(user)) {
		return { id: 'elemeno', name: 'Elemeno' };
	}
	return { id: window.SITE_ID, name: window.SITE_DISPLAY_NAME || window.SITE_ID };
}

interface UseIdentifyInterface {
	identify: (user: SessionUserFragment) => void;
	assignToTeam: (id: string) => void;
	enableEmbargo: () => void;
}

interface UseAnalyticsInterface {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
	track: (event: AnalyticsEvent | AnalyticsEvent[], properties?: any, options?: any, callback?: () => void) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
	trackLink: (element: any, event: AnalyticsEvent, properties?: any) => void;
	reset: () => void;
	enableEmbargo: () => void;
}

/**
 * Convenience method to compose a site-specific team id.
 * @param siteId string
 * @param teamId string
 * @returns combined id string
 */
function toTeamAnalyticsId({ siteId, teamId }): string {
	return `${siteId}.${teamId}`;
}

/**
 * Hook to create callback for setting analytics "group" for site.
 * @returns () => void
 */
function useAssignToSiteAnalyticsGroup(): () => void {
	const { siteId, displayName, alternateSiteName } = useSiteContext();

	return useCallback(() => {
		getAnalyticsService().group(siteId, {
			name: `${alternateSiteName || siteId} - ${displayName}`,
			plan: 'site',
			type: 'site',
		});
	}, [alternateSiteName, displayName, siteId]);
}

/**
 * Hook to create callback for setting analytics "group" for primary team.
 * @returns (teamId) => void
 */
function useAssignToTeamAnalyticsGroup(): (teamId: string) => void {
	const { siteId, alternateSiteName } = useSiteContext();
	const { data: teams } = useGroupsPublicQuery();

	return useCallback(
		(teamId) => {
			const team = teams?.find(({ id }) => id === teamId);
			getAnalyticsService().group(toTeamAnalyticsId({ siteId, teamId }), {
				name: `${alternateSiteName || siteId} - ${team?.name || teamId}`,
				plan: 'primary-team',
				type: 'primary-team',
			});
		},
		[alternateSiteName, siteId, teams],
	);
}

function normalizeEmail(email): string {
	return email.split('@').map(lowerCase).map(kebabCase).join('--');
}

function toAnalyticsUserId(user: UserIdentityFragment): string {
	if (user.analyticsUserId) {
		// new unified tracking id
		return user.analyticsUserId;
	} else if (user.cognitoUsername) {
		// old cognitoUsername
		return user.cognitoUsername;
	} else if (user.email) {
		// convert to new analytics id style
		return normalizeEmail(user.email);
	}
	return user.username;
}

// /**
//  * Hook to use when changing teams to update analytics user association.
//  * Note: this was used in the pre-team-view era, when switching teams
//  * would also change the team association in other places.  Now
//  * @returns (user) => void
//  */
// export function useAnalyticsIdentifyTeams(): (user: UserIdentityFragment, callback?: () => void) => void {
// 	const shouldIdentifyGroups = !useSiteContext().isSystemSite;
// 	const { siteId } = useSiteContext();
// 	const assignToTeam = useAssignToTeamAnalyticsGroup();

// 	return useCallback(
// 		(user: UserFragment, callback?: () => void) => {
// 			if (!shouldIdentifyGroups) {
// 				if (callback) callback();
// 				return;
// 			}

// 			const userId = toAnalyticsUserId(user);
// 			const { intercomHash } = UserProfile();

// 			const traits: AnalyticsUserTraits = { site: siteId || '-', siteType: getSiteType(), role: getRole(user) };
// 			// For some reason, primaryUnit is not being returned from the mutation in SetTeam
// 			const primaryUnit = getPrimaryTeamId(user);
// 			if (primaryUnit) {
// 				traits.primaryTeam = toTeamAnalyticsId({ siteId, teamId: primaryUnit });
// 				assignToTeam(primaryUnit);
// 				getAnalyticsService().identify(userId, intercomHash || '', traits, callback);
// 			} else {
// 				if (callback) callback();
// 			}
// 		},
// 		[assignToTeam, shouldIdentifyGroups, siteId],
// 	);
// }

export const useIdentify = (): UseIdentifyInterface => {
	const { siteId, isSystemSite } = useSiteContext();

	const assignToSite = useAssignToSiteAnalyticsGroup();
	const assignToTeam = useAssignToTeamAnalyticsGroup();
	const shouldIdentifyGroups = !useSiteContext().isSystemSite;

	const identify = useCallback(
		/**
		 * Pass User to analytics identify call.
		 * @param user
		 */
		(user: SessionUserFragment) => {
			const { firstname: firstName, lastname: lastName, email, intercomHash } = user;

			const userId = toAnalyticsUserId(user);

			// getAnalyticsService().alias(userId); // connect anonId to current user?

			const traits: AnalyticsUserTraits = {
				site: siteId || '-',
				siteType: getSiteType(),
				role: getRole(user),
				firstName,
				lastName,
				email,
				company: getCompany(user),
			};

			_userType = getRole(user);

			const primaryTeam = getPrimaryTeamId(user);
			if (shouldIdentifyGroups && primaryTeam) {
				traits.primaryTeam = toTeamAnalyticsId({ siteId, teamId: primaryTeam });
				_primaryTeam = traits.primaryTeam;
			}

			getAnalyticsService().identify(userId, traits, {
				intercomHash,
				hideIntercom: isSystemSite, // hide intercom on system sites to avoid messaging attempt during login
			});

			// add to site/team groups
			assignToSite();
			if (shouldIdentifyGroups && primaryTeam) {
				assignToTeam(primaryTeam);
			}
		},
		[assignToSite, assignToTeam, isSystemSite, shouldIdentifyGroups, siteId],
	);

	return { identify, assignToTeam, enableEmbargo };
};

/**
 * Hook to expose callback functions for analytics tracking events, that will also incorporate context information.
 * @returns { identify, track, trackLink }
 */
export const useAnalytics = (): UseAnalyticsInterface => {
	const track = useCallback(
		/**
		 * Pass event or events to analytics track call.
		 * @param eventOrEvents AnalyticsEvent (allow multiple events for transition scenarios)
		 * @param callback Optional function to call after logging the event.
		 */
		(eventOrEvents: AnalyticsEvent | AnalyticsEvent[], callback?: () => void): void => {
			if (Array.isArray(eventOrEvents)) {
				eventOrEvents.forEach((event) => getAnalyticsService().track(event, callback));
			} else {
				getAnalyticsService().track(eventOrEvents, callback);
			}
		},
		[],
	);

	const trackLink = useCallback(
		/**
		 * Wire event to call analytics track call on link click.
		 * @param event AnalyticsEvent
		 * @param callback Optional function to call after logging the event.
		 */
		(element: HTMLAnchorElement, event: AnalyticsEvent) => {
			getAnalyticsService().trackLink(element, event);
		},
		[],
	);

	const reset = useCallback(() => {
		_userType = undefined;
		getAnalyticsService().reset();
	}, []);

	return {
		track,
		trackLink,
		reset,
		enableEmbargo,
	};
};
