import { FILE_BUCKET, SEGMENT_WRITE_KEY, API_URL } from 'helpers/constants';

export enum TestType {
	XHRGet = 'Get',
	XHRPost = 'Post',
	XHRPut = 'Put',
	Script = 'Script',
	Image = 'Image',
	WebSocket = 'Web Socket',
	FileUpload = 'FileUpload',
}

enum Source {
	Elemeno = 'Elemeno',
	ElemenoStudio = 'Elemeno-2',
	Intercom = 'Intercom',
	Segment = 'Segment',
	AWS = 'AWS',
	Amplitude = 'Amplitude',
	Sentry = 'Sentry',
	JWPlayer = 'JWPlayer',
}

enum Purpose {
	AppContent = 'Application content',
	AppAPI = 'Application API',
	Auth = 'Authentication',
	Support = 'User support',
	Analytics = 'Analytics',
	FileUpload = 'File upload',
	ErrorTracking = 'Error tracking',
	Video = 'Video',
}

enum Severity {
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
}

export interface TestDataItem {
	type: TestType;
	source: Source;
	purpose: Purpose;
	severity: Severity;
	url: string;
	data?: string | URLSearchParams;
	error?: string;
	complete?: boolean;
	contentType?: string;
}

export function getTestData(): TestDataItem[] {
	return [
		{
			type: TestType.Image,
			source: Source.ElemenoStudio,
			purpose: Purpose.AppContent,
			severity: Severity.High,
			url: `https://cdn-s.lmno.care/images/5zyhoujt/pilot/ce3732fe412de250e4f6a7964399875494290e9f-16x16.png?w=16&max-h=32&fit=max`,
		},
		{
			type: TestType.Image,
			source: Source.Elemeno,
			purpose: Purpose.AppContent,
			severity: Severity.High,
			url: `https://${FILE_BUCKET}/menulogo/menu-logo-dev-200.png`,
		},
		{
			type: TestType.Image,
			source: Source.Intercom,
			purpose: Purpose.Support,
			severity: Severity.Medium,
			url: 'https://downloads.intercomcdn.com/i/o/29310/669ad6cd2b7552b60eb13549/logo-square1.png',
		},
		{
			type: TestType.XHRGet,
			source: Source.Segment,
			purpose: Purpose.Analytics,
			severity: Severity.Low,
			url: `https://cdn.segment.com/analytics.js/v1/${SEGMENT_WRITE_KEY}/analytics.min.js`,
		},
		{
			type: TestType.XHRGet,
			source: Source.Elemeno,
			purpose: Purpose.AppContent,
			severity: Severity.High,
			url: 'https://cdn.fst.lmno.care/resize=width:50,height:50,fit:scale/kyVNe0MISVOeLmKrrhae',
		},
		{
			type: TestType.XHRPost,
			source: Source.Elemeno,
			purpose: Purpose.AppAPI,
			severity: Severity.High,
			url: API_URL,
			data: 'query { getSiteConfig (site: "current site" ) { site } }',
			contentType: 'application/json; charset=UTF-8',
		},
		{
			type: TestType.XHRPost,
			source: Source.Elemeno,
			purpose: Purpose.AppAPI,
			severity: Severity.High,
			url: 'https://api2.lmno.care/prod/data',
			data: 'query { getSiteConfig (site: "current site" ) { site } }',
			contentType: 'application/json; charset=UTF-8',
		},
		{
			// expected to fail due to malformed request
			type: TestType.XHRGet,
			source: Source.AWS,
			purpose: Purpose.Auth,
			severity: Severity.High,
			url: 'https://authservice.elemenohealth.com/oauth2/userInfo',
		},
		{
			// expected to fail due to malformed request
			type: TestType.XHRPost,
			source: Source.AWS,
			purpose: Purpose.Auth,
			severity: Severity.High,
			url: 'https://cognito-idp.us-east-1.amazonaws.com',
			data: 'test',
		},
		{
			type: TestType.XHRPost,
			source: Source.Segment,
			purpose: Purpose.Analytics,
			severity: Severity.Low,
			url: 'https://api.segment.io/v1/t',
			data: 'test',
		},
		{
			// expected to fail due to malformed request
			type: TestType.XHRPost,
			source: Source.Amplitude,
			purpose: Purpose.Analytics,
			severity: Severity.Low,
			url: 'https://api.amplitude.com',
			data: 'test',
		},
		{
			// expected to fail due to malformed request
			type: TestType.XHRPost,
			source: Source.Sentry,
			purpose: Purpose.ErrorTracking,
			severity: Severity.Medium,
			url: 'https://sentry.io/api/85617/store/?sentry_key=493ec61cc510487aabcff26656284684&sentry_version=7',
			data: 'test',
		},
		{
			type: TestType.Script,
			source: Source.Intercom,
			purpose: Purpose.Support,
			severity: Severity.Medium,
			url: 'https://widget.intercom.io/widget/ttj24mek',
		},
		{
			type: TestType.Script,
			source: Source.Intercom,
			purpose: Purpose.Support,
			severity: Severity.Medium,
			url: 'https://js.intercomcdn.com/shim.latest.js',
		},
		{
			type: TestType.Script,
			source: Source.JWPlayer,
			purpose: Purpose.Video,
			severity: Severity.Medium,
			url: 'https://cdn.jwplayer.com/libraries/txjgVV7g.js',
		},
		{
			type: TestType.Script,
			source: Source.Amplitude,
			purpose: Purpose.Analytics,
			severity: Severity.Low,
			url: 'https://cdn.amplitude.com/libs/amplitude-5.2.2-min.gz.js',
		},
		{
			type: TestType.WebSocket,
			source: Source.Intercom,
			purpose: Purpose.Support,
			severity: Severity.Medium,
			url: 'wss://nexus-websocket-a.intercom.io/pubsub/5-RMZzqM1WeKQlDocKsvgsp0_zs9JRHW8LpiTe9GHIDdAOE7KCHRQfsw_wMgnzyaaErgLxTbuiN2cwZ0vUIxdfwAyOWhgd8l9-Fady',
		},
		{
			type: TestType.FileUpload,
			source: Source.Elemeno,
			purpose: Purpose.FileUpload,
			severity: Severity.Medium,
			url: API_URL,
		},
	];
}

export const compareTestType = (a, b) => {
	const typeMap = {
		[TestType.XHRGet]: 7,
		[TestType.XHRPost]: 6,
		[TestType.XHRPut]: 5,
		[TestType.Script]: 4,
		[TestType.Image]: 3,
		[TestType.WebSocket]: 2,
		[TestType.FileUpload]: 1,
	};
	return typeMap[b.type] - typeMap[a.type];
};

export const compareTestSeverity = (a, b) => {
	const severityMap = { [Severity.High]: 3, [Severity.Medium]: 2, [Severity.Low]: 1 };
	return severityMap[b.severity] - severityMap[a.severity];
};
